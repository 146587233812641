<template>
  <div class="home">
    <NavBar></NavBar>
    <div
      id="kt_content_container"
      class="d-flex flex-column-fluid align-items-start container-xxl"
    >
      <div class="content flex-row-fluid">
        <div class="row g-5 g-xl-10">
          <div class="col-md-4 mb-xl-10">
            <div class="card card-flush">
              <div
                class="
                  card-header
                  rounded
                  bgi-no-repeat
                  bgi-size-cover
                  bgi-position-y-top
                  bgi-position-x-center
                  align-items-start
                  h-250px
                "
                style="background-color: #D18E9E"
              >
                <h3
                  class="
                    card-title
                    align-items-start
                    flex-column
                    text-white
                    pt-15
                  "
                >
                  <span class="fw-bolder fs-2x mb-3">Hello,Maria</span>
                  <div class="fs-4 text-white">
                    <span class="opacity-75">You have</span>
                    <span class="position-relative d-inline-block">
                      <a
                        href=""
                        class="
                          link-white
                          opacity-75-hover
                          fw-bolder
                          d-block
                          mb-1
                        "
                        >4 tasks</a
                      >
                      <!--begin::Separator-->
                      <span
                        class="
                          position-absolute
                          opacity-50
                          bottom-0
                          start-0
                          border-2 border-white border-bottom
                          w-100
                        "
                      ></span>
                      <!--end::Separator-->
                    </span>
                    <span class="opacity-75">to complete</span>
                  </div>
                </h3>
              </div>

              <div class="card-body mt-n20">
                <!--begin::Stats-->
                <div class="mt-n20 position-relative">
                  <!--begin::Row-->
                  <div class="row g-3 g-lg-6">
                    <!--begin::Col-->
                    <div class="col-6">
                      <!--begin::Items-->
                      <CourseCard></CourseCard>
                      <!--end::Items-->
                    </div>
                    <!--end::Col-->
                    <!--begin::Col-->
                    <div class="col-6">
                      <!--begin::Items-->
                      <CertificatesCard></CertificatesCard>

                      <!--end::Items-->
                    </div>
                    <!--end::Col-->
                    <!--begin::Col-->
                    <div class="col-6">
                      <!--begin::Items-->
                      <ScoreCard></ScoreCard>
                      <!--end::Items-->
                    </div>
                    <!--end::Col-->
                    <!--begin::Col-->
                    <div class="col-6">
                      <!--begin::Items-->
                      <HoursCard></HoursCard>
                      <!--end::Items-->
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Row-->
                </div>
                <!--end::Stats-->
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="row">
              <div class="col">
                <div class="card">
                  <div class="card-boy">
                    <div class="align-middle title-morado" style="background-color:white">
                      For new training or questions, please contact us at
                      info@spalivemd.com or +1 (469) 403 6844
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" style="margin-top: 50px">
              <div class="col">
                <MainCourseCard></MainCourseCard>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <NewCourse></NewCourse>
              </div>
            </div>
          </div>

          <div class="col-md-2">
            
            <ad-card></ad-card>
            <ad-card></ad-card>
            <ad-card></ad-card>

       
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import CourseCard from "@/components/mini-cards/CourseCard.vue";
import CertificatesCard from "@/components/mini-cards/CertificatesCard.vue";
import ScoreCard from "@/components/mini-cards/ScoreCard.vue";
import HoursCard from "@/components/mini-cards/HoursCard.vue";
import MainCourseCard from "@/components/MainCourseCard.vue";
import NewCourse from "@/components/NewCourse.vue";
import AdCard from "@/components/AdCard.vue";
export default {
  name: "HomeView",
  components: {
    NavBar,
    CourseCard,
    CertificatesCard,
    ScoreCard,
    HoursCard,
    MainCourseCard,
    NewCourse,
    AdCard
  },
  data() {
    return {};
  },
};
</script>

<style>
.home::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background-color: #dd839f;
}
.first-row {
  margin-top: 100px !important;
}
#kt_content_container {
  margin-top: 100px;
}
.title-morado {
  color: #9483b8;
  text-align: center;
  padding: 5px;
}

/*media query mobile */
@media (max-width: 575.98px) {
 
  .img-fluid{
    min-width: 100%;
  }
  .align-items-start {
    align-items: center;
  }
}
</style>
