import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    token: null,
  },
  mutations: {
    setUser(state, user) {
      console.log("setUser", user)
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    
  },
  actions: {

    async LogIn({commit}, user) {
      await commit('setUser', user); 
    },
    async LogOut({commit}) {
      await commit('setUser', null);
      await commit('setToken', null);
    }
  },
  getters: {
    isAuthenticated: (state) => !!state.user,
  },
})
