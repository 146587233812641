<template>
  <div id="kt_header" class="header align-items-stretch" style="padding: 10px;">
    <!--begin::Container-->
    <div class="container-xxl d-flex align-items-center">
      <!--begin::Heaeder menu toggle-->
      <div
        class="d-flex topbar align-items-center d-lg-none ms-n2 me-3"
        title="Show aside menu"
      >
        <div
          class="
            btn btn-icon btn-active-light-primary btn-custom
            w-30px
            h-30px
            w-md-40px
            h-md-40px
          "
          id="kt_header_menu_mobile_toggle"
        >
          <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
          <span class="svg-icon svg-icon-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                fill="currentColor"
              ></path>
              <path
                opacity="0.3"
                d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                fill="currentColor"
              ></path>
            </svg>
          </span>
          <!--end::Svg Icon-->
        </div>
      </div>
      <!--end::Heaeder menu toggle-->
      <!--begin::Header Logo-->
      <div class="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0">
        <a href="/">
          <img
            alt="Logo"
            src="@/assets/images/logo.png"
            class="logo-default h-55px"
          />
          <img
            alt="Logo"
            src="@/assets/images/logo.png"
            class="logo-sticky h-55px"
          />
        </a>
      </div>
      <!--end::Header Logo-->
      <!--begin::Wrapper-->
      <div
        class="
          d-flex
          align-items-stretch
          justify-content-between
          flex-lg-grow-1
        "
      >
        <!--begin::Navbar-->
        <div class="d-flex align-items-stretch" id="kt_header_nav">
          <!--begin::Menu wrapper-->
          <div
            class="header-menu align-items-stretch"
            data-kt-drawer="true"
            data-kt-drawer-name="header-menu"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="{default:'200px', '300px': '250px'}"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
            data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
          >
            <!--begin::Menu-->
            <div
              class="
                menu
                menu-lg-rounded
                menu-column
                menu-lg-row
                menu-state-bg
                menu-title-gray-700
                menu-state-icon-primary
                menu-state-bullet-primary
                menu-arrow-gray-400
                fw-bold
                my-5 my-lg-0
                align-items-stretch
              "
              id="#kt_header_menu"
              data-kt-menu="true"
            >
              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="bottom-start"
                class="menu-item here menu-lg-down-accordion me-lg-1"
              >
                <span class="menu-link py-3">
         
                  <router-link to="/" class="menu-title" > Home</router-link>
                  <span class="menu-arrow d-lg-none"></span>
                </span>
              </div>
              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="bottom-start"
                class="menu-item menu-lg-down-accordion me-lg-1"
              >
                <span class="menu-link py-3"> 
                   <router-link to="/courses" class="menu-title" > Courses</router-link>
                  <span class="menu-arrow d-lg-none"></span>
                </span>
              </div>
              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="bottom-start"
                class="menu-item menu-lg-down-accordion me-lg-1"
              >
                <span class="menu-link py-3">
                  
                     <router-link to="/media-kit" class="menu-title" >Media Kit</router-link>
                  <span class="menu-arrow d-lg-none"></span>
                </span>
              </div>
              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="bottom-start"
                class="menu-item menu-lg-down-accordion me-lg-1"
              >
                <span class="menu-link py-3">
                  
                  <span class="menu-arrow d-lg-none"></span>
                </span>
              </div>
              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="bottom-start"
                class="menu-item menu-lg-down-accordion me-lg-1"
              >
                <span class="menu-link py-3">
                     <router-link to="/how-to-use-our-app" class="menu-title" >How to use our App</router-link>
 
                  <span class="menu-arrow d-lg-none"></span>
                </span>
              </div>
            </div>
            <!--end::Menu-->
          </div>
          <!--end::Menu wrapper-->
        </div>
        <!--end::Navbar-->
        <!--begin::Toolbar wrapper-->
        <div class="topbar d-flex align-items-stretch flex-shrink-0">
          <!--begin::User-->
          <div
            class="d-flex align-items-center me-n3 ms-1 ms-lg-3"
            id="kt_header_user_menu_toggle"
          >
            <!--begin::Menu wrapper-->
            <div
              class="
                btn
                btn-icon
                btn-active-light-primary
                btn
                btn-icon
                btn-active-light-primary
                btn-custom
                w-30px
                h-30px
                w-md-40px
                h-md-40px
              "
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
            >
              <img
                class="h-30px w-30px rounded"
                src="https://dummyimage.com/100x100/807980/fff"
                alt=""
              />
            </div>
            <!--begin::User account menu-->
            <div
              class="
                menu
                menu-sub
                menu-sub-dropdown
                menu-column
                menu-rounded
                menu-gray-800
                menu-state-bg
                menu-state-primary
                fw-bold
                py-4
                fs-6
                w-275px
              "
              data-kt-menu="true"
            >
              <!--begin::Menu item-->
              <div class="menu-item px-3">
                <div class="menu-content d-flex align-items-center px-3">
                  <!--begin::Avatar-->
                  <div class="symbol symbol-50px me-5">
                    <img
                      alt="Logo"
                      src="https://dummyimage.com/100x100/807980/fff"
                    />
                  </div>
                  <!--end::Avatar-->
                  <!--begin::Username-->
                  <div class="d-flex flex-column">
                    <div class="fw-bolder d-flex align-items-center fs-5">
                      Maria Hubbert
                      <span
                        class="
                          badge badge-light-success
                          fw-bolder
                          fs-8
                          px-2
                          py-1
                          ms-2
                        "
                        >Pro</span
                      >
                    </div>
                  </div>
                  <!--end::Username-->
                </div>
              </div>
              <!--end::Menu item-->
              <!--begin::Menu separator-->
              <div class="separator my-2"></div>
              <!--end::Menu separator-->
              <!--begin::Menu item-->
              <div class="menu-item px-5"> 
                <router-link to="/profile" class="menu-link px-5" >My Profile</router-link> 
              </div>
              <!--end::Menu item-->
              <!--begin::Menu item-->
              <div class="menu-item px-5">
                <a href="" class="menu-link px-5">
                  <span class="menu-text">My Courses</span>
                  <span class="menu-badge">
                    <span
                      class="
                        badge badge-light-danger badge-circle
                        fw-bolder
                        fs-7
                      "
                      >3</span
                    >
                  </span>
                </a>
              </div>
              <!--end::Menu item-->
              <!--begin::Menu item-->
              <div
                class="menu-item px-5"
                data-kt-menu-trigger="hover"
                data-kt-menu-placement="left-start"
              >
                <a href="#" class="menu-link px-5">
                  <span class="menu-title">My Subscription</span>
                </a>
              </div>
              <!--end::Menu item-->
              <!--begin::Menu item-->
              <div class="menu-item px-5">
                <a href="" class="menu-link px-5">My Statements</a>
              </div>
              <!--end::Menu item-->
              <!--begin::Menu separator-->
              <div class="separator my-2"></div>
              <!--end::Menu separator-->

              <!--begin::Menu item-->
              <div class="menu-item px-5">
                <a @click="logout" class="menu-link px-5">Sign Out</a>
              </div>
              <!--end::Menu item-->
            </div>
            <!--end::User account menu-->
            <!--end::Menu wrapper-->
          </div>
          <!--end::User -->
          <!--begin::Aside mobile toggle-->
          <!--end::Aside mobile toggle-->
        </div>
        <!--end::Toolbar wrapper-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
export default {
  name: "NavBar",
  
  methods: {
 
    toggleAside: function() {
      this.$aside.toggle();
    },
    toggleAsideMobile: function() {
      this.$asideMobile.toggle();
    },
    logout: function() {
      
      localStorage.clear();
      this.$store.dispatch("LogOut").then(() => {
        this.$router.push("/login");
      });
    }
  }
};
</script>

<style>
.menu-link {
  color: #9483b8 !important;
  text-decoration: none !important;
}
.separator {
  border-bottom: 1px solid #ed72a0 !important;
}
.menu-title{
  text-decoration: none !important;
}
#kt_header{
  background-color: #dd839f;
}
</style>