import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "../store";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {requiresAuth: true}
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  },
  {
    path: '/login',
    name: 'login',
    component: function () {
      return import('../views/LoginView.vue')
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: function () {
      return import('../views/ProfileView.vue')
    },
    meta: {requiresAuth: true},
    
  },
  {
    path: '/courses',
    name: 'courses',
    component: function () {
      return import('../views/courses/CourseView.vue')
    },
    meta: {requiresAuth: true},
    
  },
  {
    path: '/material',
    name: 'material',
    component: function () {
      return import('../views/courses/DocumentView.vue')
    },
    meta: {requiresAuth: true},
    
  },
  {
    path: '/consents',
    name: 'conesents',
    component: function () {
      return import('../views/ConsentsView.vue')
    },
    meta: {requiresAuth: true},
    
  },
  {
    path: '/how-to-use-our-app',
    name: 'how-to-use-our-app',
    component: function () {
      return import('../views/how-to-use/HowToUseOurAppView.vue')
    },
    meta: {requiresAuth: true},

  },{
    path: '/media-kit',
    name: 'media-kit',
    component: function () {
      return import('../views/media-kit/MediaKitView.vue')
    },
    meta: {requiresAuth: true},

  }




]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    //console.log("--->",store.getters.isAuthenticated)
    var token = localStorage.getItem('token')
    //console.log("token",token)
    if(token!= '') {
      store.dispatch('LogIn', token)
      if (store.getters.isAuthenticated) {
        next()
        return
      }
    }
    if (store.getters.isAuthenticated) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
